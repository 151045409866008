import type { ActionFieldType, ChangeStatus, DataType, Features } from '@shared/data/constants'
import type { NullString } from '@shared/utils/Types'
import type { DimensionConfigModel } from '@/usecases/models/DimensionConfigModel'

export type EncodingType = 'one-hot' | Features.ORDINAL | Features.IDENTITY

export interface ActionFieldModelType {
  column: string
  category: ActionFieldType
  description?: NullString
  default_value: string | null
  data_type: DataType
  encoding: EncodingType | null
  encoding_order: string | null
  sort: number

  type?: 'action_features' | 'features' | 'predicted_value'
}

export class ActionFieldModel {
  public data: ActionFieldModelType
  public fromTemplate: boolean
  public parentDimension: string
  constructor(_data: ActionFieldModelType, _parentDimension: string, _fromTemplate: boolean = false) {
    this.data = _data
    this.parentDimension = _parentDimension
    this.fromTemplate = _fromTemplate
  }
}

export interface ActionValueModel {
  name: string
  value: NullString | number
}

export interface ActionConfigModel {
  fields: ActionValueModel[]
}

export interface ActionModel {
  changeStatus?: ChangeStatus
  isDuplicate?: boolean
  isNew?: boolean
  key?: NullString
  originalKey?: string
  fields: ActionValueModel[]
}

export interface ActionPayload {
  dimension: DimensionConfigModel
  action: ActionModel
}

export interface ConfigDimensionActionBank {
  actions: ActionConfigModel[]
  readonly name: string // dimension name
  fields: ActionFieldModelType[]
}

export type ConfigDimensionOnlyActionsAndName = Pick<ConfigDimensionActionBank, 'actions' | 'name'>

export interface DimensionActionBank {
  actions: ActionModel[]
  readonly name: string // dimension name
  fields: ActionFieldModel[]
}

export interface DimensionActionBankPartial extends Partial<DimensionActionBank> {}

export interface ActionBanksForOutput {
  label: string
  dimension: string
  description?: NullString
  value: string
  type: string
  suffix?: any
}

export interface ActionBanksForTree {
  [dimension: string]: ActionBanksForOutput[]
}

export interface ActionBanksForOutputPartial extends Partial<ActionBanksForOutput> {}

export enum ActionColumn {
  ACTION_ID = 'action_id',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
  OFAF_WEEKLY_SENDS = 'ofaf_weekly_sends',
  DESCRIPTION = 'description',
  ACTION_STATUS = 'action_status',
  FREQUENCY_VALID_FOR = 'frequency_valid_for',
}

export enum ActionColumnIndex {
  ACTION_ID = 0,
  ACTION_STATUS = 1,
}
