import { store } from '@app/store'
import ModalConfirm from '@shared/components/basic/modal/ModalConfirm.vue'
import { warn } from '@shared/utils/logger'
import { createSharedComposable } from '@vueuse/core'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import AppSettings from '@app/AppSettings'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import { dimensionNames } from '../data/l4_mapAndLists'
import { useLvl4Usecase } from './useLvl4Usecase'
import DeploymentsService from '@/deployments/services/DeploymentsService'
import UseCaseService from '@/usecases/services/UseCaseService'
import DAGService from '@/dags/services/DAGService'
import { UseCaseStatus } from '@/shared/data/constants'

function _useLvl4Deployments(experimenterName: string) {
  const { clientName, pipelineName } = AppSettings
  const { usecase, isLoading } = useLvl4Usecase(clientName.value, pipelineName.value, experimenterName)

  const isLaunching = ref<boolean>(false)
  const isPromoting = ref<boolean>(false)
  const isPausing = ref<boolean>(false)

  const changesList = ref<Array<string>>([])

  const launchCallbacks: (() => void)[] = []
  function registerLaunchCallback(callback: () => void) {
    if (usecase.value?.status === UseCaseStatus.LIVE) {
      warn('registerLaunchCallback -> Experimenter is live, callback will not be registered.')
      return
    }

    launchCallbacks.push(callback)
  }

  const getActionBankChanges = async () => {
    let hasChangesToPromote = false

    if (usecase.value?.status === UseCaseStatus.LIVE) {
      const actionBankChanges = await DeploymentsService.getActionBankChanges(
        'default',
        usecase.value.name,
        'draft',
        'live',
      )

      Object.entries(actionBankChanges).forEach(([dimension, changes]) => {
        if (Object.values(changes).some(hasChanges => hasChanges)) {
          changesList.value.push(dimensionNames[dimension as keyof typeof dimensionNames])
          if (!hasChangesToPromote) {
            hasChangesToPromote = true
          }
        }
      })
    }

    return hasChangesToPromote
  }

  const { state: hasChangesToPromote, isLoading: loadingChangesToPromote, error, execute: fetchActionBankChanges } = useAsyncState(
    () => getActionBankChanges(),
    undefined,
  )

  watch(usecase, async () => {
    await fetchActionBankChanges()
  })

  const { state: isExperimenterPaused, isLoading: isPauseStateLoading, error: experimenterPauseStateLoadingError, execute: updateExperimenterPauseState } = useAsyncState(
    async () => DAGService.isUseCasePaused(store.getters['client/client'].name, experimenterName),
    false,
  )

  async function launch() {
    // initial validation
    if (!pipelineName.value) {
      handlePortalError(new Error('Pipeline is not set'), { defaultUserErrorText: 'Pipeline is not set' })
      return
    }

    if (!usecase.value?.id) {
      handlePortalError(new Error('Use case not found'), { defaultUserErrorText: 'Use case not found' })
      return
    }

    isLaunching.value = true
    try {
      await DeploymentsService.migratePipeline(pipelineName.value, 'draft', 'live')
    }
    catch (error: any) {
      handlePortalError(error, { defaultUserErrorText: 'Failed to migrate pipeline' })
      throw error
    }

    try {
      await DeploymentsService.migrateUsecase(pipelineName.value, experimenterName, 'draft', 'live')
    }
    catch (error: any) {
      handlePortalError(error, { defaultUserErrorText: 'Failed to migrate usecase' })
      throw error
    }

    // set use case to live
    const payload = {
      status: UseCaseStatus.LIVE,
    }

    try {
      await UseCaseService.updateUseCase(usecase.value.id, payload)
    }
    catch (error: any) {
      handlePortalError(error, { defaultUserErrorText: 'Failed to update usecase status' })
      throw error
    }

    usecase.value.status = UseCaseStatus.LIVE

    // execute all registered launch callbacks
    launchCallbacks.forEach(cb => cb())
    // unregister callbacks after that
    launchCallbacks.length = 0

    isLaunching.value = false
  }

  async function promote() {
    const pipeline = store.getters['pipeline/pipeline']?.name

    // initial validation
    if (!pipeline) {
      handlePortalError(new Error('Pipeline is not set'), { defaultUserErrorText: 'Pipeline is not set' })
      return
    }

    if (!usecase.value?.id) {
      handlePortalError(new Error('Use case not found'), { defaultUserErrorText: 'Use case not found' })
      return
    }

    isPromoting.value = true

    try {
      await DeploymentsService.migrateUsecase(pipeline, experimenterName, 'draft', 'live')
      hasChangesToPromote.value = false
      changesList.value = []
    }
    catch (error: any) {
      handlePortalError(error, { defaultUserErrorText: 'Failed to migrate usecase' })
      throw error
    }

    isPromoting.value = true
  }

  async function pause() {
    const { isCanceled } = await createConfirmDialog(ModalConfirm, {
      title: 'Pause experimenter',
      htmlMessage: `You are about to pause the experiment named <b>${experimenterName}</b>. No more emails will be sent. Are you sure?`,
      isOpened: true,
    }).reveal()

    if (!isCanceled) {
      isPausing.value = true
      await DAGService.pauseUsecaseInDAG(store.getters['client/client'].name, experimenterName)
      await updateExperimenterPauseState()
      isPausing.value = false
    }
  }

  async function unpause() {
    const { isCanceled } = await createConfirmDialog(ModalConfirm, {
      title: 'Run experimenter',
      htmlMessage: `You are about to start the experiment named <b>${experimenterName}</b>. Are you sure?`,
      isOpened: true,
    }).reveal()

    if (!isCanceled) {
      isLaunching.value = true
      await DAGService.unpauseUseCaseInDAG(store.getters['client/client'].name, experimenterName)
      await updateExperimenterPauseState()
      isLaunching.value = false
    }
  }

  const isStatusChanging = computed(() => isLaunching.value || isPromoting.value || isPausing.value || isPauseStateLoading.value)

  const experimenterStatus = computed(() => usecase.value?.status)

  return {
    experimenterStatus,
    hasChangesToPromote,
    changesList,
    loadingChangesToPromote,
    registerLaunchCallback,
    isLaunching,
    isPromoting,
    isPausing,
    error,
    launch,
    promote,
    isExperimenterPaused,
    isPauseStateLoading,
    experimenterPauseStateLoadingError,
    updateExperimenterPauseState,
    pause,
    unpause,
    isStatusChanging,
    isLoading,
  }
}

export const useLvl4Deployments = createSharedComposable(_useLvl4Deployments)
