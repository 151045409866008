import process from 'node:process'
import { getApiPath } from '@app/utils/common-http-client'

export default class AuthService {
  static getSignInLocation = () => {
    const host = location.host || 'localhost:8080'
    let auth0Url = `${getApiPath()}/connect/auth0`
    if (host.startsWith('preview-branch-')) {
      const branch = host.split('.')?.[0]
      auth0Url = `${getApiPath()}/connect/auth0?state=${branch}`
    }

    return auth0Url
  }
}
