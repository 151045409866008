import unionBy from 'lodash/unionBy'
import { getRowValue } from '@/usecases/composables/usecaseUtils'
import { ActionFieldType, ActionStatus, ChangeStatus, DataType, DimensionKey } from '@shared/data/constants'
import { isEmpty, isEqual } from '@shared/utils/helpers'

import type {
  ActionConfigModel,
  ActionFieldModelType,
  ActionModel,
  ActionValueModel,
  ConfigDimensionActionBank,
  DimensionActionBank,
} from '@/usecases/models/server/ActionModel'
import {
  ActionColumn,
  ActionFieldModel,
} from '@/usecases/models/server/ActionModel'

export const DEFAULT_COLUMN_NAME = {
  ACTION_ID: ActionColumn.ACTION_ID,
  cadence: {
    MONDAY: ActionColumn.MONDAY,
    TUESDAY: ActionColumn.TUESDAY,
    WEDNESDAY: ActionColumn.WEDNESDAY,
    THURSDAY: ActionColumn.THURSDAY,
    FRIDAY: ActionColumn.FRIDAY,
    SATURDAY: ActionColumn.SATURDAY,
    SUNDAY: ActionColumn.SUNDAY,
  },
  frequency: {
    OFAF_WEEKLY_SENDS: ActionColumn.OFAF_WEEKLY_SENDS,
    DESCRIPTION: ActionColumn.DESCRIPTION,
  },
  day: {
    MONDAY: ActionColumn.MONDAY,
    TUESDAY: ActionColumn.TUESDAY,
    WEDNESDAY: ActionColumn.WEDNESDAY,
    THURSDAY: ActionColumn.THURSDAY,
    FRIDAY: ActionColumn.FRIDAY,
    SATURDAY: ActionColumn.SATURDAY,
    SUNDAY: ActionColumn.SUNDAY,
    DESCRIPTION: ActionColumn.DESCRIPTION,
    FREQUENCY_VALID_FOR: ActionColumn.FREQUENCY_VALID_FOR,

  },
  ACTION_STATUS: ActionColumn.ACTION_STATUS,
}

/**
 * Returns object for the action parameter with specific name, type and sort fields.
 * @param name
 * @param type
 * @param sort
 * @param category
 * @param defaultValue
 * @returns {{column, data_type: string, description: null, default_value: null, value_list: null, sort: number, category: string, encoding: null, encoding_order: null}}
 */
export function getActionParameterObject(name: string, dimensionName: string, type: DataType = DataType.STRING, sort: number = 1, category: ActionFieldType = ActionFieldType.FEATURE, defaultValue: string | null = null) {
  return new ActionFieldModel(
    {
      column: name,
      category,
      data_type: type,
      encoding: null,
      encoding_order: null,
      default_value: defaultValue,
      description: null,
      sort,
    } as ActionFieldModelType,
    dimensionName,
  )
}

/**
 * Returns array of default action parameter objects using prepared columns (like 'action_id')
 * For the 'cadence' dimension it will return 7 additional columns
 *
 * Result:
 *
 * [
 *   {
 *     column: 'action_id',
 *     category: 'parameter',
 *     data_type: 'string',
 *     encoding: null,
 *     encoding_order: null,
 *     default_value: null,
 *     description: null,
 *     value_list: null,
 *     sort: 0
 *   },
 *   {
 *     column: 'action_name',
 *     category: 'parameter',
 *     data_type: 'string',
 *     encoding: null,
 *     encoding_order: null,
 *     default_value: null,
 *     description: null,
 *     sort: 1
 *   }
 * ]
 *
 */
export function getTemplateParametersByDimensionName(dimensionName: string, oldParameters: ActionFieldModel[] = []) {
  const templateParameters: ActionFieldModel[] = [
    getActionParameterObject(DEFAULT_COLUMN_NAME.ACTION_ID, dimensionName, DataType.INTEGER, 0, ActionFieldType.PARAMETER),
    getActionParameterObject(
      DEFAULT_COLUMN_NAME.ACTION_STATUS,
      dimensionName,
      DataType.STRING,
      1,
      ActionFieldType.PARAMETER,
      ActionStatus.ACTIVE,
    ),
  ].concat(
    new Map([
      [
        DimensionKey.CADENCE.toString(),
        [
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.MONDAY, dimensionName, DataType.FLOAT, 2),
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.TUESDAY, dimensionName, DataType.FLOAT, 3),
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.WEDNESDAY, dimensionName, DataType.FLOAT, 4),
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.THURSDAY, dimensionName, DataType.FLOAT, 5),
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.FRIDAY, dimensionName, DataType.FLOAT, 6),
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.SATURDAY, dimensionName, DataType.FLOAT, 7),
          getActionParameterObject(DEFAULT_COLUMN_NAME.cadence.SUNDAY, dimensionName, DataType.FLOAT, 8),
        ],
      ],
      [
        DimensionKey.FREQUENCY.toString(),
        [
          getActionParameterObject(DEFAULT_COLUMN_NAME.frequency.OFAF_WEEKLY_SENDS, dimensionName, DataType.INTEGER, 2),
          getActionParameterObject(DEFAULT_COLUMN_NAME.frequency.DESCRIPTION, dimensionName, DataType.STRING, 4, ActionFieldType.PARAMETER, 'send(s) per week'),
        ],
      ],
      [
        DimensionKey.DAYSOFWEEK.toString(),
        [
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.MONDAY, dimensionName, DataType.BOOLEAN, 2, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.TUESDAY, dimensionName, DataType.BOOLEAN, 3, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.WEDNESDAY, dimensionName, DataType.BOOLEAN, 4, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.THURSDAY, dimensionName, DataType.BOOLEAN, 5, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.FRIDAY, dimensionName, DataType.BOOLEAN, 6, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.SATURDAY, dimensionName, DataType.BOOLEAN, 7, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.SUNDAY, dimensionName, DataType.BOOLEAN, 8, ActionFieldType.FEATURE, 'False'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.DESCRIPTION, dimensionName, DataType.STRING, 10, ActionFieldType.PARAMETER, 'Send on the following days'),
          getActionParameterObject(DEFAULT_COLUMN_NAME.day.FREQUENCY_VALID_FOR, dimensionName, DataType.INTEGER, 11, ActionFieldType.PARAMETER, '0'),
        ],
      ],
    ]).get(dimensionName) || [],
  )

  return unionBy(oldParameters, templateParameters, 'data.column')
}

export function getActionItemField(action: ActionConfigModel | ActionModel, fieldName: string): ActionValueModel | undefined {
  return action.fields.find((item: ActionValueModel) => item.name === fieldName)
}
export function getActionFieldValue(action: ActionConfigModel | ActionModel, fieldName: string) {
  return getActionItemField(action, fieldName)?.value
}

export function getActionId(action: ActionConfigModel | ActionModel) {
  return getActionFieldValue(action, ActionColumn.ACTION_ID)
}

export function getWrappedActionModel(action: ActionConfigModel): ActionModel {
  return { isDuplicate: false, isNew: false, key: getActionId(action)?.toString(), fields: action.fields }
}

export function getUnwrappedActionConfigModel(action: ActionModel): ActionConfigModel {
  return { fields: action.fields } as ActionConfigModel
}
export function getWrappedActionBank(bank: ConfigDimensionActionBank): DimensionActionBank {
  return {
    ...bank,
    actions: (bank.actions || []).map((item: ActionConfigModel) => getWrappedActionModel(item)),
    fields: (bank.fields || []).map((item: ActionFieldModelType) => {
      const emptyCopy = getActionParameterObject(
        item.column,
        bank.name,
        item.data_type,
        item.sort,
        item.category,
        item.default_value,
      )
      return new ActionFieldModel({ ...emptyCopy.data, ...item }, bank.name)
    }),
  }
}

export function getUnwrappedActionBank(bank: DimensionActionBank): ConfigDimensionActionBank {
  return {
    ...bank,
    actions: (bank.actions || []).map((item: ActionModel) => getUnwrappedActionConfigModel(item)),
    fields: (bank.fields || []).map((item: ActionFieldModel) => item.data),
  }
}

export function getActionValueChangeStatus(valueModel: ActionValueModel, liveValueModel?: ActionValueModel): ChangeStatus {
  if (valueModel && !liveValueModel) { return ChangeStatus.ADDED }

  if (!isEmpty(valueModel.value) && isEmpty(liveValueModel?.value)) { return ChangeStatus.ADDED }
  if (isEmpty(valueModel.value) && !isEmpty(liveValueModel?.value)) { return ChangeStatus.DELETED }

  if (!isEmpty(valueModel.value) && !isEmpty(liveValueModel?.value) && valueModel.value !== liveValueModel?.value) { return ChangeStatus.CHANGED }

  return ChangeStatus.NONE
}

export class ActionBankFeatureIsMissingError extends Error {
  constructor() {
    super('One or more feature column is missing. It must be restored.')
    this.name = 'ActionBankFeatureIsMissingError'
  }
}

export class ActionBankFeatureAreDifferentError extends Error {
  constructor() {
    super('One or more feature property is different. It must be restored.')
    this.name = 'ActionBankFeatureAreDifferentError'
  }
}
export class ActionBankLiveActionIsModifiedError extends Error {
  missedAction: ActionModel | null = null
  constructor(missedAction: ActionModel) {
    super('An action is missed in the current action bank')
    this.name = 'ActionBankLiveActionIsModifiedError'
    this.missedAction = missedAction
  }
}

export class ActionBankRequiredActionIsEmptyError extends Error {
  emptyActions: ActionModel[] | null = null
  constructor(emptyActions: ActionModel[]) {
    super('A required feature is empty in one or more actions in the current action bank.')
    this.name = 'ActionBankRequiredActionIsEmptyError'
    this.emptyActions = emptyActions
  }
}

export function isCurrentActionsAreEqualToLiveActions(
  currentActionBank: DimensionActionBank,
  liveActionBank: DimensionActionBank,
) {
  if (isEqual(currentActionBank.actions, liveActionBank.actions)) { return true }

  let isChanged = false

  for (let i = 0; i < currentActionBank.actions.length; i++) {
    const actionModel: ActionModel = currentActionBank.actions[i]
    const liveRow = liveActionBank.actions.find((item: ActionModel) => item.key === actionModel.originalKey)
    const actionFieldValues: ActionValueModel[] | undefined = (actionModel).fields

    for (let j = 0; j < actionFieldValues.length; j++) {
      const actionDataValue = actionFieldValues[j]
      const liveDataValue: ActionValueModel | undefined = liveRow ? getRowValue(liveRow, actionDataValue.name) : undefined
      if (actionDataValue && !liveDataValue) { isChanged = true }
      if (liveDataValue && actionDataValue.value !== liveDataValue.value) { isChanged = true }
    }
  }

  return !isChanged
}

export function isActionBankPromotable(currentActionBank: DimensionActionBank, liveActionBank?: DimensionActionBank): boolean {
  const OFFERFIT_EMPTY_SYMBOL = 'OF_EMPTY_STRING'

  if (!liveActionBank?.actions?.length) { throw new Error('isActionBankPromotable must be executed only if there is the live action bank') }

  if (isCurrentActionsAreEqualToLiveActions(currentActionBank, liveActionBank)) { return false }

  const requiredLiveFeatures = liveActionBank.fields.filter(
    (field: ActionFieldModel) => field.data.category === ActionFieldType.FEATURE,
  )
  const requiredCurrentFeatures = currentActionBank.fields.filter(
    (field: ActionFieldModel) => field.data.category === ActionFieldType.FEATURE,
  )

  const requiredLiveFeatureStrings = requiredLiveFeatures.map((item: ActionFieldModel) => JSON.stringify(item))
  const requiredCurrentFeatureStrings = requiredCurrentFeatures.map((item: ActionFieldModel) => JSON.stringify(item))

  if (requiredLiveFeatures.length > requiredCurrentFeatures.length) { throw new ActionBankFeatureIsMissingError() }

  requiredLiveFeatureStrings.sort()
  const limitedCurrentFeatureStrings = requiredCurrentFeatureStrings.concat()
  limitedCurrentFeatureStrings.length = requiredLiveFeatures.length
  limitedCurrentFeatureStrings.sort()

  const featuresAreEqual = isEqual(requiredLiveFeatureStrings, limitedCurrentFeatureStrings)

  if (!featuresAreEqual) { throw new ActionBankFeatureAreDifferentError() }

  const requiredFeatureNames = requiredCurrentFeatures.map((field: ActionFieldModel) => field.data.column)
  const actionsWithEmptyRequiredFeatures = currentActionBank.actions.filter((action: ActionModel) =>
    action.fields.some((value: ActionValueModel) => requiredFeatureNames.includes(value.name) && !value.value),
  )

  if (actionsWithEmptyRequiredFeatures.length) { throw new ActionBankRequiredActionIsEmptyError(actionsWithEmptyRequiredFeatures) }

  const currentActionBankActionsPrints = currentActionBank.actions.map((item: ActionModel) =>
    item.fields
      .map((field: ActionValueModel) =>
        field.name === ActionColumn.ACTION_STATUS ? '-status-' : `${field.name}:${field.value || OFFERFIT_EMPTY_SYMBOL}`,
      )
      .join('_'),
  )

  const liveActionBankActionMap: Record<string, ActionModel> = {}
  const liveActionBankActionsPrints = liveActionBank.actions.map((item: ActionModel) =>
    item.fields
      .map((field: ActionValueModel) => {
        const print
          = field.name === ActionColumn.ACTION_STATUS ? '-status-' : `${field.name}:${field.value || OFFERFIT_EMPTY_SYMBOL}`
        liveActionBankActionMap[print] = item
        return print
      })
      .join('_'),
  )

  for (let i = 0; i < liveActionBankActionsPrints.length; i++) {
    const liveActionPrint = liveActionBankActionsPrints[i]
    if (!currentActionBankActionsPrints.includes(liveActionPrint)) {
      throw new ActionBankLiveActionIsModifiedError(liveActionBankActionMap[liveActionPrint])
    }
  }

  return true
}
