import type { StrapiData } from '@shared/utils/Types'
import http from '@app/utils/http-client'
import type { PipelineConfigModel } from '@/pipelines/models/PipelineConfigModel.ts'
import type { MetadataModel } from '@/pipelines/models/server/PipelineModel.ts'
import type { UseCaseConfigModel } from '@/usecases/models/UseCaseConfigModel.ts'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants.ts'
import type { ExperimenterFormState, FormsStateStrapiModel } from '@/level4/models/server/FormStateModel'
import type { Frequency } from '@/usecases/models/FrequencyModel'

export default class FormsStateService {
  static async loadExperimenters(clientName: string, pipelineName: string = 'default') {
    const response = await http.get<FormsStateStrapiModel[]>(`/clients/${clientName}/pipelines/${pipelineName}/experimenters`, { cache: false })
    return response.data
  }

  static async saveFormsState(formStateId: number, formsStateData: Partial<ExperimenterFormState>) {
    const payload = formsStateData
    if (formsStateData.state) {
      payload.state = formsStateData.state
    }
    return (await http.put<StrapiData<ExperimenterFormState>>(`/form-states/${formStateId}`, { data: payload })).data
  }

  static async updateSelectedTypeAndDuration(clientName: string, pipelineName: string, experimenterName: string, type: string, startDate: string | null, endDate: string | null) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-selected-type-and-duration`, { data: { type, startDate, endDate } })
  }

  static async updateSelectedTimeOfDay(clientName: string, pipelineName: string, experimenterName: string, selectedTimeOfDay: [number, number]) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-selected-time-of-day`, { data: { selectedTimeOfDay } })
  }

  static async updateSelectedTimeZone(clientName: string, pipelineName: string, experimenterName: string, selectedTimeZone: string) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-selected-time-zone`, { data: { selectedTimeZone } })
  }

  static async getRecentTimeZones(clientName: string, pipelineName: string) {
    const response = await http.get<{ selectedTimeZone: string, updatedSelectedTimeZoneAt: string }[]>(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/time-zones`, { cache: false })

    return response.data
  }

  static async getRecentTimeOfDays(clientName: string, pipelineName: string) {
    const response = await http.get<{ selectedTimeOfDay: [number, number], updatedSelectedTimeOfDayAt: string }[]>(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/time-of-days`, { cache: false })

    return response.data
  }

  static async updateSelectedAudienceIds(clientName: string, pipelineName: string, experimenterName: string, selectedAudienceIds: string[]) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-selected-audience-ids`, { data: { selectedAudienceIds } })
  }

  static async updateSelectedAudienceRegimePercent(clientName: string, pipelineName: string, experimenterName: string, selectedOfferfitGroupPercent: number, selectedControlGroupPercent: number) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-selected-audiences-regime-percents`, { data: { selectedControlGroupPercent, selectedOfferfitGroupPercent } })
  }

  static async updateSelectedFrequenciesAndDays(clientName: string, pipelineName: string, experimenterName: string, selectedFrequencies: Frequency[] | undefined, selectedDays: string[] | undefined) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-selected-frequencies-and-days`, { data: { selectedFrequencies, selectedDays } })
  }

  static async setMarketingPlatform(clientName: string, pipelineName: string, experimenterName: string, platform: INTEGRATION_PLATFORM) {
    return http.put<FormsStateStrapiModel>(`clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/set-marketing-platform`, {
      data: { platform },
    })
  }

  static async setMarketingCampaign(clientName: string, pipelineName: string, experimenterName: string, campaign: string) {
    return http.put<FormsStateStrapiModel>(`clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/set-marketing-campaign`, {
      data: { campaign },
    })
  }

  static async updateConfigFromPyMS(clientName: string, pipelineName: string, experimenterName: string, dry_run: boolean = false) {
    return http.put<{
      pipelineMetadata: MetadataModel
      pipelineConfig: PipelineConfigModel
      useCaseConfig: UseCaseConfigModel
    }>(`clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/update-config`, {
      data: { dry_run },
    })
  }

  static async renameStore(formStateId: number, newName: string) {
    await http.put(`/form-states/${formStateId}`, { data: { name: newName } })
  }

  static async deleteFormState(formStateId: number) {
    await http.delete(`/form-states/${formStateId}`)
  }

  static async createFormsState(clientName: string, pipelineName: string, experimenterName: string) {
    const response = await http.post<FormsStateStrapiModel>(`/clients/${clientName}/pipelines/${pipelineName}/experimenters`, { data: { experimenterName } })

    return response.data
  }

  static async loadExperimenter(clientName: string, pipelineName: string, experimenterName: string) {
    return (await http.get<FormsStateStrapiModel>(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}`, { cache: false })).data
  }

  static async updateBAUReportingConfiguration(clientName: string, pipelineName: string, experimenterName: string, isAddingBAU: boolean, selectedCampaignIds?: string[], audienceId?: string, dry_run: boolean = false) {
    return http.put(`/clients/${clientName}/pipelines/${pipelineName}/experimenters/${experimenterName}/bau-config`, { data: { isAddingBAU, selectedCampaignIds, audienceId, dry_run } })
  }
}
