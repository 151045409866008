import type { AxiosResponse } from 'axios'
import qs from 'qs'

import { PYTHON_MS_API_PREFIX } from '@shared/data/constants'
import type { PythonMSResponse } from '@shared/utils/Types'
import http from '@app/utils/http-client'
import type { DAGModel } from '@/dags/models/server/DAGModel'
import type { DAGModelHistoryListItem } from '@/pipelines/models/server/PipelineModel'

function paramsByClient(name: string) {
  return {
    product_env: 'live',
    client: name,
  }
}

export default class DAGService {
  static async getDAGByClient(clientName: string): Promise<DAGModel> {
    if (!clientName) {
      throw new Error('clientName is required')
    }
    let response: AxiosResponse<DAGModel>

    try {
      response = await http.post(`${PYTHON_MS_API_PREFIX}/strapi/get_client_dag`, paramsByClient(clientName))
    }
    catch (error: any) {
      throw new Error(`Failed to get DAG config for ${clientName}`)
    }

    return response.data
  }

  static async validateDAG(dag: DAGModel) {
    let response: AxiosResponse<string[]>
    try {
      response = await http.post('/dags/validate', { config: dag.config })
    }
    catch (error: any) {
      throw new Error(`Failed to validate DAG config for ${dag.name}`)
    }

    return response.data
  }

  static async isUseCasePaused(clientName: string, useCaseName: string) {
    if (!useCaseName) {
      throw new Error('useCaseName is required')
    }
    if (!clientName) {
      throw new Error('clientName is required')
    }

    try {
      const dagDataFromLive = await DAGService.getDAGByClient(clientName)
      return dagDataFromLive.config.dag.usecases.find((usecase: any) => usecase.name === useCaseName).enabled === false
    }
    catch (error: any) {
      throw new Error(`Failed to get DAG config for ${clientName} from live`)
    }
  }

  static async pauseUsecaseInDAG(clientName: string, useCaseName: string) {
    if (!useCaseName) {
      throw new Error('useCaseName is required')
    }
    if (!clientName) {
      throw new Error('clientName is required')
    }

    const dagDataFromLive = await DAGService.getDAGByClient(clientName)

    if (!dagDataFromLive) {
      throw new Error(`Failed to get DAG config for ${clientName}`)
    }

    try {
      await http.patch(`${PYTHON_MS_API_PREFIX}/strapi/toggle_pause_usecase/${dagDataFromLive.id}/${useCaseName}`, { pause: true })
    }
    catch (error: any) {
      throw new Error(`Failed to update use case in DAG config for ${clientName}`)
    }
  }

  static async unpauseUseCaseInDAG(clientName: string, useCaseName: string) {
    if (!useCaseName) {
      throw new Error('useCaseName is required')
    }
    if (!clientName) {
      throw new Error('clientName is required')
    }

    const dagDataFromLive = await DAGService.getDAGByClient(clientName)

    if (!dagDataFromLive) {
      throw new Error(`Failed to get DAG config for ${clientName}`)
    }

    try {
      await http.patch(`${PYTHON_MS_API_PREFIX}/strapi/toggle_pause_usecase/${dagDataFromLive.id}/${useCaseName}`, { pause: false })
    }
    catch (error: any) {
      throw new Error(`Failed to update use case in DAG config for ${clientName}`)
    }
  }

  static async getDAGHistoryList(clientName: string): Promise<DAGModelHistoryListItem[]> {
    const response = await http.get(`${PYTHON_MS_API_PREFIX}/strapi/get_dag_history?${qs.stringify(paramsByClient(clientName))}`)

    return (response.data.history as DAGModelHistoryListItem[]) || []
  }

  static async saveDAG(dagModel: DAGModel, clientName: string): Promise<PythonMSResponse> {
    let response: AxiosResponse<PythonMSResponse>
    try {
      response = await http.put(`${PYTHON_MS_API_PREFIX}/strapi/update_client_dag`, {
        client: clientName,
        manual: dagModel.manual,
        config: dagModel.config,
        product_env: 'live',
      })
    }
    catch (error: any) {
      throw new Error(`Failed to save DAG config for ${clientName}`)
    }

    return response.data
  }
}
