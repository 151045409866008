<script setup lang="ts">
import { VueMonacoEditor, useMonaco } from '@guolao/vue-monaco-editor'
import type { editor } from 'monaco-editor'
import * as monaco from 'monaco-editor'
import { configureMonacoYaml } from 'monaco-yaml'
import fullConfigSchema from '@assets/schemas/full_config.json'
import usecaseNoAbConfigSchema from '@assets/schemas/usecase_no_ab_config.json'

withDefaults(defineProps<Props>(), {  })
export interface Props {
  height?: string
  schemaType?: 'full' | 'usecase-excluding-action-bank'
}

const code = defineModel<string>('code', { default: '' })

const MONACO_EDITOR_OPTIONS: editor.IStandaloneEditorConstructionOptions = {
  automaticLayout: true,
  formatOnType: true,
  formatOnPaste: true,
  glyphMargin: true,
  codeLens: true,
}

const schemaPath = computed(() => {
  return `file:///${__props.schemaType}.yaml`
})

const { monacoRef, unload } = useMonaco()

onMounted(() => {
  if (!__props.schemaType) { return }
  configureMonacoYaml(monaco, {
    enableSchemaRequest: true,
    yamlVersion: '1.2',
    schemas: [{
      uri: 'https://example.com/pipeline.json',
      fileMatch: ['full.yaml'],
      schema: fullConfigSchema,
    }, {
      uri: 'https://example.com/usecase_no_ab.json',
      fileMatch: ['usecase-excluding-action-bank.yaml'],
      schema: usecaseNoAbConfigSchema,
    }],
  })
})

onUnmounted(() => !monacoRef.value && unload())
</script>

<template>
  <VueMonacoEditor
    v-if="!!code"
    ref="monacoRef"
    v-model:value="code"
    :options="MONACO_EDITOR_OPTIONS"
    :style="{ height: height ?? '800px' }"
    :path="schemaPath"
    theme="vs-dark"
    language="yaml"
    border
    placeholder="Yaml text here"
  />
</template>
