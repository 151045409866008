<script setup lang="ts">
import useOfferfitEnvironment from "@app/composables/useOfferfitEnvironment";

const { showTopbar, environment, portalVersion } = useOfferfitEnvironment()


const topbarColorMap = {
  development: '#1052ff',
  staging: '#fcb300',
}

const topbarColor = topbarColorMap[environment]
const opened = ref(false)

const descriptions = [
  {
    label: 'env',
    value: environment,
    icon: 'i-solar-planet-bold-duotone'
  },
  {
    label: 'version',
    value: portalVersion,
    icon: 'i-solar-box-bold-duotone'
  },
]
</script>

<template>
  <div
    v-if="showTopbar"
    w="full"
    sticky="~"
    top="0"
    z="100"
    @click="opened = true"
    @mouseleave="opened = false"
    bg="gray-50"
  >
    <div
      :style="{backgroundColor: topbarColor}"
      cursor-pointer
      bg="hero-diagonal-stripes-black/20"
      min-h="[5px]"
    />
    <NCollapseTransition :show="opened" class="container mx-auto">
      <div class="description-list px-4 p-4 flex gap-8">
        <div v-for="description in descriptions" :key="description.label" class="flex items-center gap-2 text-gray-600">
          <div :class="description.icon"/>
          <div class="flex flex-col">
            <span class="text-xs">{{ description.label }}</span>
            <b>{{ description.value }}</b>
          </div>
        </div>
      </div>
    </NCollapseTransition>
  </div>
</template>

