import { transactionId } from '@app/utils/common-http-client'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/vue'
import type { Vue } from '@sentry/vue/types/types'
import type { Router } from 'vue-router'

export function initSentry(app: Vue, router: Router, userName: string, userEmail: string, userId: string, userRole: string, client: string, isReadOnly: boolean) {
  // eslint-disable-next-line no-console
  console.info('Sentry is enabled')
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    environment: import.meta.env.VITE_APP_ENVIRONMENT as string,
    release: `portal-client@${PORTAL_VERSION}`,
    ignoreErrors: ['NotFoundError', 'No usecase_performance data found for', 'No metrics data found for'],
    initialScope: {
      user: { email: userEmail, id: userId, role: userRole?.toUpperCase(), name: userName },
      tags: {
        client,
        isReadOnly,
        transaction_id: transactionId,
      },
    },
    integrations: [
      Sentry.replayIntegration({
        networkDetailAllowUrls: ['https://api.portal.beta.offerfit.ai/api', 'https://api.portal.offerfit.ai/api'],
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    replaysSessionSampleRate: import.meta.env.VITE_APP_ENVIRONMENT === 'staging' ? 0.05 : 0.6,
    replaysOnErrorSampleRate: import.meta.env.VITE_APP_ENVIRONMENT === 'staging' ? 0.1 : 1.0,
    tracesSampleRate: import.meta.env.VITE_APP_ENVIRONMENT === 'staging' ? 0.5 : 1.0,
    tracePropagationTargets: [
      'localhost:8080',
      'localhost:1337',
      'portal.offerfit.ai',
      'api.portal.offerfit.ai',
      'api.portal.beta.offerfit.ai',
    ],
  })
}
