import type { PopoverPlacement } from 'naive-ui'

import type { NullString } from '@shared/utils/Types'

const DEFAULT_YML_HEADER: string = 'Usage in a config'
const DEFAULT_TEXT_HEADER: string = 'Read more'

export interface ServerTooltip {
  readonly id: number
  readonly uid: string
  readonly text: string
}

export interface ServerTooltips {
  readonly createdAt: string
  readonly updatedAt: string
  readonly locale: 'en'
  readonly Tooltips: ServerTooltip[]
  readonly localizations: {
    readonly data: string[]
  }
}

export enum TOOLTIP {
  VALIDATION_UNIQUE_VALUES_FOR_COMPOUND_FIELDS = 'validation_unique_values_for_compound_fields',
  VALIDATION_MIN_ROWS = 'validation_min_rows',
  VALIDATION_MAX_ROWS = 'validation_max_rows',
  PREPROCESSING_NEW_COLUMN_NAME = 'preprocessing_new_column_name',
  PREPROCESSING_PRIMARY_KEY = 'preprocessing_primary_key',
  FEATURES_DAYS_BACK = 'features_days_back',
  FAIL_IF_NOT_PRESENT = 'fail_if_not_present',
  FILE_PATH_FORMAT = 'file_path_format',
  PARTITION_COLUMN = 'partition_column',
  CPU_LIMIT = 'cpu_limit',
  MEMORY_LIMIT = 'memory_limit',
  SOURCE_FORMAT = 'source_format',
  CUSTOMER_IDENTIFIER = 'customer_identifier',
  DATETIME_COLUMN = 'datetime_column',
  EVENTS_PROPERTIES = 'events_properties',
  EVENT_TYPE = 'event_type',
  EVENTS_EVENT_TIMESTAMP_COL = 'events_event_timestamp_col',
  EVENT_FILTER = 'event_filter',
  FILTER_VALUE_DATA_TYPE = 'filter_value_data_type',
  ASSET_FILE_UPDATE = 'asset_file_update',
  BASE_DATA_ICON = 'base_data_icon',
  SAMPLE_DATA_WARNING_ICON = 'sample_data_warning_icon',
  CONTROL_GROUP_EXPRESSION_BUILDER = 'control_group_expression_builder',
  DIMENSION_SEND_DECISION_TO = 'dimension_send_decision_to',
  REWARD_TRIGGER = 'reward_trigger',
  REWARD_PRIMARY = 'reward_primary',
  REWARD_SECONDARY = 'reward_secondary',
  REWARD_DAYS_TO_WAIT = 'reward_days_to_wait',
  REWARD_UNSUBSCRIPTION_PENALTY = 'reward_unsubscription_penalty',
  ACTION_PARAMETER_USE_AS_FEATURE = 'action_parameter_use_as_feature',
  ACTION_PARAMETER_ENCODING_TYPE = 'action_parameter_encoding_type',
  ACTION_PARAMETER_DEFAULT_VALUE = 'action_parameter_default_value',
  ACTIVATION_DELAY = 'delay_days_to_activation',
  CUSTOMER_GROUP_TYPE = 'customer_group_type',
  OUTPUT_CONNECTION = 'output_connection',
  OUTPUT_PATH = 'output_path',
  OUTPUT_DELIVERY_TIME = 'output_delivery_time',
  FILL_INPUT = 'fill_input',
  FIELDS_FOR_OUTPUT = 'fields_for_output',
  CUSTOMER_POPULATION = 'customer_population',
  TARGETED_AUDIENCE = 'targeted_audience',
  FEATURE_ENGINEERING_FILTER = 'feature_engineering_filter',
  REWARD_CONVERSION_ATTR = 'reward_conversion_attr',
  REWARD_TURN_OFF_DATE = 'reward_turn_off_date',
  REWARD_PENALTY_EVENT = 'reward_penalty_event',
  REWARD_PENALTY_VALUE = 'reward_penalty_value',
  TARGET_METRIC_COL_TOOLTIP = 'target_metric_col',
  TRANSFORM_DIFFERENCE = 'transform_difference',
  TRANSFORM_DESTINATION_MANY = 'transform_destination_many',
  TRANSFORM_DELIMITER_TOOLTIP = 'transform_delimiter',
  ASSET_TYPE_TOOLTIP = 'asset_type',
  ASSET_CONNECTION_TOOLTIP = 'asset_connection',
  DAG_MANUAL_TOOLTIP = 'dag_manual_tooltip',
  RUN_MIGRATION_VIEW_DIFF_NO_USECASES = 'run_migration_view_diff_no_usecases',
  RUN_MIGRATION_VIEW_DIFF_NO_USECASES_OR_PIPELINES = 'run_migration_view_diff_no_usecases_or_pipelines',
  PROCESSED_DATA_TOOLTIP = 'processed_data',
}

export interface TooltipContent {
  id: TOOLTIP
  text?: NullString
  yaml?: NullString
  tooltipPlacement?: PopoverPlacement
  collapsedText?: NullString
  collapseHeader?: NullString
}

const yamlDictionary: Map<TOOLTIP, string> = new Map([
  [
    TOOLTIP.PREPROCESSING_NEW_COLUMN_NAME,
    `...
preprocess:
    assets:
        asset_name:
            ...
            rename_cols:
                OLD COLUMN1: new_column1
                OLD_COLUMN 2: new_column2
            ...`,
  ],
  [
    TOOLTIP.PREPROCESSING_PRIMARY_KEY,
    `...
preprocess:
    assets:
        asset_name:
            ...
            pk_cols:
               - column1
               - column2
            ...`,
  ],
])

const collapseHeaderDictionary: Map<TOOLTIP, string> = new Map([])
const collapsedTextDictionary: Map<TOOLTIP, string> = new Map([])

const tooltipPlacementDictionary: Map<TOOLTIP, PopoverPlacement> = new Map([
  [TOOLTIP.PREPROCESSING_NEW_COLUMN_NAME, 'bottom-end'],
  [TOOLTIP.PREPROCESSING_PRIMARY_KEY, 'bottom-end'],
])

const enUSTooltipDictionary: Map<TOOLTIP, string> = new Map([
  [
    TOOLTIP.VALIDATION_UNIQUE_VALUES_FOR_COMPOUND_FIELDS,
    'Ensures combination of values in specified columns is unique (fails if there are any duplicate combinations of values)',
  ],
  [TOOLTIP.VALIDATION_MIN_ROWS, 'Minimum number of rows the asset should have (fails if fewer rows are present)'],
  [TOOLTIP.VALIDATION_MAX_ROWS, 'Maximum number of rows the asset should have (fails if more rows are present)'],
  [
    TOOLTIP.PREPROCESSING_PRIMARY_KEY,
    'Column or columns which constitute a primary key in this asset (if there is no primary key, select "No" for each column). A column (or set of columns) is a primary key if no two rows should contain the exact same combination of the column(s)',
  ],
  [TOOLTIP.PREPROCESSING_NEW_COLUMN_NAME, 'Standardized column name (must be lowercase, without spaces)'],
  [TOOLTIP.EVENTS_PROPERTIES, 'Select which column values in the data asset to record with the event.'],
  [TOOLTIP.EVENTS_EVENT_TIMESTAMP_COL, 'Column with event timestamp values'],
  [TOOLTIP.EVENTS_EVENT_TIMESTAMP_COL, 'Column with event timestamp values'],
  [TOOLTIP.FEATURES_DAYS_BACK, 'Number of days to aggregate across'],
  [
    TOOLTIP.FAIL_IF_NOT_PRESENT,
    'Stop pipeline if asset is not present. If asset does not arrive daily, or is optional, select "No"',
  ],
  [
    TOOLTIP.FILE_PATH_FORMAT,
    'Bucket path to which the asset files are uploaded, with %Y standing for 4-digit year, %m for 2-digit month, and %d for 2-digit day. Extension can be skipped.',
  ],
  [
    TOOLTIP.PARTITION_COLUMN,
    'A datetime column that used to create different partitions when storing the asset for improving efficiency. If there are multiple datetime columns presented in the asset, please choose the one is will be frequently used during feature engineering for loading records in the past N days. In case of doubt, please use "offerfit_received_ts"',
  ],
  [TOOLTIP.CPU_LIMIT, 'Amount of CPUs for validation and preprocessing (2 is a good default)'],
  [
    TOOLTIP.MEMORY_LIMIT,
    'Amount of memory required for validation and preprocessing (20G is a good default; more for very large assets)',
  ],
  [TOOLTIP.CUSTOMER_IDENTIFIER, 'Column with customer ids'],
  [TOOLTIP.DATETIME_COLUMN, 'If this is time series data, select the corresponding datetime column'],
  [TOOLTIP.SOURCE_FORMAT, 'Asset file format'],
  [
    TOOLTIP.EVENT_TYPE,
    'Engagement events correspond to customer actions (e.g., opening an email).\n Activation events correspond to marketing actions (e.g., sending an email). \n Penalty events correspond to undesired actions (e.g., unsubscribing from an email). \n Conversion events are events by which an user conversion is counted (eg a user subscribing).',
  ],
  [TOOLTIP.EVENT_FILTER, 'Filter expression that selects these events in the asset'],
  [TOOLTIP.BASE_DATA_ICON, 'This is the asset that defines the population set'],
  [TOOLTIP.SAMPLE_DATA_WARNING_ICON, 'This asset\'s source data is a sample set'],
  [TOOLTIP.CONTROL_GROUP_EXPRESSION_BUILDER, 'Add filters to select the control group customers'],
  [
    TOOLTIP.DIMENSION_SEND_DECISION_TO,
    'The action for this dimension is decided first and then sent as contextual information to the dimensions below',
  ],
  [
    TOOLTIP.REWARD_TRIGGER,
    'An experience is created for each trigger (which can be an activation, engagement, or recommendation event)',
  ],
  [
    TOOLTIP.REWARD_PRIMARY,
    'A reward sent to the agent when a conversion event occurs, after recommendations have been sent. This rewards the primary objective of the use case.',
  ],
  [
    TOOLTIP.REWARD_SECONDARY,
    'Engagement event which correlates with conversion and is partially rewarded. Typically, this would be an email click, landing page visit, call answered, etc.',
  ],
  [
    TOOLTIP.REWARD_DAYS_TO_WAIT,
    'Days from trigger event to wait to register an experience. E.g., if the primary reward is "Conversion" and "Days to wait" is 10, then if a conversion has happened within 10 days of the trigger, the action is counted as "engaged" and if not, then "not engaged".',
  ],
  [
    TOOLTIP.REWARD_UNSUBSCRIPTION_PENALTY,
    'Penalty for unsubscription. Should roughly equal the expected incremental value of the customer remaining subscribed.',
  ],
  [
    TOOLTIP.ACTION_PARAMETER_ENCODING_TYPE,
    'Features must be numeric. If this parameter is categorical (string), and use as feature is selected, indicate how this parameter should be encoded.',
  ],
  [TOOLTIP.ACTION_PARAMETER_DEFAULT_VALUE, 'Default value of the parameter given to new actions'],
  [TOOLTIP.OUTPUT_CONNECTION, 'GCS Bucket to which recommendations will be sent'],
  [TOOLTIP.OUTPUT_PATH, 'Recommendation file bucket path'],
  [TOOLTIP.OUTPUT_DELIVERY_TIME, 'Time at which recommendations are generated'],
  [TOOLTIP.FILL_INPUT, 'Value to write, if feature output is undefined'],
  [TOOLTIP.FIELDS_FOR_OUTPUT, 'Choose additional fields to be added to the recommendations file'],
  [
    TOOLTIP.CUSTOMER_POPULATION,
    'The list of  customers for whom we will create features. Excluded customers will not be considered in any Use Case',
  ],
  [
    TOOLTIP.FEATURE_ENGINEERING_FILTER,
    'Filters are a set of conditions that can either be true or false for a particular customer. '
    + 'All of the conditions specified in the set of filters must be met in order for a row to be considered. '
    + 'If no filters are defined, then all of the conditions have been met and all rows will be considered.',
  ],
  [
    TOOLTIP.REWARD_CONVERSION_ATTR,
    'Conversion attribution is the system used to determine which action(s) '
    + 'contributed to a successful conversion. There are two possible methods:'
    + ' 1) Last touch gives all credit to the "closest matching" past recommendation, '
    + 'when comparing to the conversion characteristics.'
    + ' 2) All touches gives credit to all previous recommendations. '
    + 'Please see the full help page for more details.',
  ],
  [
    TOOLTIP.REWARD_TURN_OFF_DATE,
    'A Secondary Reward is intended to be temporary, generally used immediately after go-live to speed up learning. '
    + 'The Turn off date is the date to disable the secondary reward, and is recommended to be 30 days after go-live.',
  ],
  [
    TOOLTIP.REWARD_PENALTY_EVENT,
    'Penalty events are events that should be discouraged from happening, such as customer\'s unsubscribing from emails.',
  ],
  [
    TOOLTIP.REWARD_PENALTY_VALUE,
    'A value set by the user is used as base for the penalty calculation (in place of the target metric\'s value), '
    + 'allowing for greater flexibility. '
    + 'The recommended value for a penalty is the "expected loss of the customer being unsubscribed vs subscribed".',
  ],
  [TOOLTIP.ASSET_FILE_UPDATE, 'Local time'],
  [
    TOOLTIP.TARGETED_AUDIENCE,
    'If no filter is defined, the audience for this use case includes all the customers present in the customer population.',
  ],
  [TOOLTIP.TARGET_METRIC_COL_TOOLTIP, 'The column which defines the metric that is optimized for by the model.'],
  [TOOLTIP.TRANSFORM_DIFFERENCE, 'First selected column is the minuend'],
  [TOOLTIP.TRANSFORM_DESTINATION_MANY, 'Comma separated'],
  [
    TOOLTIP.ACTIVATION_DELAY,
    'The number of days between the date the recommendation is created and the date the client actually receives the communication.',
  ],
  [TOOLTIP.CUSTOMER_GROUP_TYPE, 'Select a group type. A group type definition will appear after selection.'],
  [
    TOOLTIP.TRANSFORM_DELIMITER_TOOLTIP,
    'The character that marks the beginning or end of the unit of data you are trying to transform.',
  ],
  [
    TOOLTIP.ASSET_TYPE_TOOLTIP,
    'Asset type describes whether the same records will be received on the following day. If all records from the previous day will appear in the next day\'s file, then each daily file contains a "snapshot" of the underlying table. Otherwise, if only update records or new records will appear in the next day\'s file, then each daily file contains only "incremental" records from the previous day.',
  ],
  [
    TOOLTIP.ASSET_CONNECTION_TOOLTIP,
    'If the data asset is available in the client bucket, use file path format. If bucket connection is not yet set up, begin with a sample upload.',
  ],
  [
    TOOLTIP.DAG_MANUAL_TOOLTIP,
    'In automatic mode, it is allowed to change only the CPU, memory and schedule. Also, you can disable/enable tasks. All other changes will be overwritten.<br/><br/>DAG configuration won\'t get new tasks in manual mode.',
  ],
  // [TOOLTIP.ACTION_PARAMETER_USE_AS_FEATURE, 'Should this parameter be used by the agents to make recommendations?'],
  [TOOLTIP.RUN_MIGRATION_VIEW_DIFF_NO_USECASES, 'You don\'t have any use cases. Please create a use case first.'],
  [
    TOOLTIP.RUN_MIGRATION_VIEW_DIFF_NO_USECASES_OR_PIPELINES,
    'You don\'t have any use cases or pipelines. Please create a use case or pipeline first.',
  ],
  [
    TOOLTIP.PROCESSED_DATA_TOOLTIP,
    'This preview will show either preprocessed, joined or transformed data, depending on which data processing tasks were configured. As these tasks ingest the data from the previous step, the data from the last processing task is shown.',
  ],
])

export const serverTooltips: Map<TOOLTIP, string> = new Map([])

export const getTooltipContent: (id: TOOLTIP) => TooltipContent = (id: TOOLTIP) => {
  return {
    id,
    text: serverTooltips.get(id) || enUSTooltipDictionary.get(id) || null,
    yaml: yamlDictionary.get(id) || null,
    collapseHeader:
      collapseHeaderDictionary.get(id) || yamlDictionary.get(id)
        ? DEFAULT_YML_HEADER
        : collapsedTextDictionary.get(id)
          ? DEFAULT_TEXT_HEADER
          : null,
    collapsedText: collapsedTextDictionary.get(id) || null,
    tooltipPlacement: tooltipPlacementDictionary.get(id) || null,
  } as TooltipContent
}
