import { getQueryString } from '@shared/utils/helpers'
import type { AxiosResponse, CancelTokenSource } from 'axios'
import axios from 'axios'
import { DEFAULT_CSV_SEPARATOR } from '@shared/data/constants'
import { store } from '@app/store'
import type { BucketFileInfo, IDataAsset } from '@shared/utils/Types'
import { escapeTabs } from '@shared/utils/transformHelpers'
import http from '@app/utils/http-client'
import type { BucketAssetMetadata } from '@/data-assets/models/server/BucketAssetMetadata'

let isBucketFileInfoRequestPending: boolean = false
let currentBucketFileInfoRequestCancelSource: CancelTokenSource | null = null

let currentGetHeadersRequestId: string | null = null
let currentGetHeadersRequestCancelSource: CancelTokenSource | null = null

export default class AssetDataService {
  static GET_HEADERS_FROM_BUCKET_FILE_URL: string = '/data-asset/bucket/headers'
  static GET_ROWS_FROM_BUCKET_FILE_URL: string = '/data-asset/bucket/file-data'
  static GET_FILE_INFO_FROM_BUCKET_URL: string = '/data-asset/bucket/file-info'
  static DATA_ASSET_CREATED: string = '/data-asset/created'
  static DATA_ASSET_DELETED: string = '/data-asset/deleted'

  static async dataAssetCreated(dataAsset: IDataAsset): Promise<BucketAssetMetadata | void> {
    if (store.getters.isReadonlyMode) {
      return
    }

    const body = {
      clientId: store.getters['client/client'].id,
      dataAsset,
    }

    const response = await http.post(AssetDataService.DATA_ASSET_CREATED, body)

    return response?.data
  }

  static async dataAssetDeleted(dataAsset: IDataAsset): Promise<BucketAssetMetadata | void> {
    if (store.getters.isReadonlyMode) {
      return
    }

    const body = {
      clientId: store.getters['client/client'].id,
      dataAsset,
    }

    const response = await http.post(AssetDataService.DATA_ASSET_DELETED, body)

    return response?.data
  }

  static cancelDataAssetLoading() {
    if (isBucketFileInfoRequestPending && currentBucketFileInfoRequestCancelSource) {
      currentBucketFileInfoRequestCancelSource.cancel('Deduplication')
      isBucketFileInfoRequestPending = false
      currentBucketFileInfoRequestCancelSource = null
    }

    if (currentGetHeadersRequestId && currentGetHeadersRequestCancelSource) {
      currentGetHeadersRequestCancelSource.cancel('Deduplication')
      currentGetHeadersRequestId = null
      currentGetHeadersRequestCancelSource = null
    }
  }

  static async getMetadataForBucketFile(
    dataFormat: string,
    fileName: string,
    assetName: string,
    config: undefined | { separator: string },
    withoutBlocking: boolean = false,
    useFewerRows: boolean = false,
  ): Promise<BucketAssetMetadata | void> {
    let response: AxiosResponse

    const queryParams = {
      client: store.getters['client/client'].name,
      pipeline: store.getters['pipeline/pipeline']?.name,
      dataFormat,
      fileName,
      separator: escapeTabs(config?.separator) || DEFAULT_CSV_SEPARATOR,
      useFewerRows,
    }

    const requestID = `GET_${assetName}_headers-${getQueryString(queryParams)}`

    if (!withoutBlocking) {
      AssetDataService.cancelDataAssetLoading()
    }

    currentGetHeadersRequestId = requestID

    currentGetHeadersRequestCancelSource = axios.CancelToken.source()

    try {
      response = await http.get(`${AssetDataService.GET_HEADERS_FROM_BUCKET_FILE_URL}?${getQueryString(queryParams)}`, {
        id: requestID,
        cancelToken: currentGetHeadersRequestCancelSource.token,
      })

      currentGetHeadersRequestId = null
    }
    catch (error: any) {
      if (axios.isCancel(error)) {
        return
      }

      currentGetHeadersRequestId = null
      throw new Error(error.message)
    }

    return response?.data as BucketAssetMetadata
  }

  static async getDataRowsFromBucketFile(
    dataFormat: string,
    fileName: string,
    config?: { separator: string },
    columns?: string[],
    limit?: number,
  ): Promise<Record<string, number | string | null>[]> {
    const queryParams = {
      clientId: store.getters['client/client'].id,
      pipeline: store.getters['pipeline/pipeline']?.name,
      dataFormat,
      fileName,
      columns,
      limit,
      separator: escapeTabs(config?.separator) || DEFAULT_CSV_SEPARATOR,
    }

    const response = await http.get(`${AssetDataService.GET_ROWS_FROM_BUCKET_FILE_URL}?${getQueryString(queryParams)}`)

    return response?.data
  }

  static async getDataFileInfoFromBucket(
    filePathFormat: string,
    withoutBlocking: boolean = false,
  ): Promise<'None' | BucketFileInfo> {
    let response: AxiosResponse | undefined

    const queryParams = {
      clientId: store.getters['client/client'].id,
      pipeline: store.getters['pipeline/pipeline']?.name,
      filePathFormat,
    }

    if (!withoutBlocking) {
      AssetDataService.cancelDataAssetLoading()
    }

    currentBucketFileInfoRequestCancelSource = axios.CancelToken.source()
    isBucketFileInfoRequestPending = true

    try {
      response = await http.get(`${AssetDataService.GET_FILE_INFO_FROM_BUCKET_URL}?${getQueryString(queryParams)}`, {
        cancelToken: currentBucketFileInfoRequestCancelSource.token,
        cache: false,
      })
      isBucketFileInfoRequestPending = false
    }
    catch (error: any) {
      if (error.response && error.response.statusCode !== 404) {
        isBucketFileInfoRequestPending = false
        throw new Error(error.message)
      }
      else {
        isBucketFileInfoRequestPending = false
        return 'None'
      }
    }

    return response?.data as BucketFileInfo
  }
}
