/* eslint-disable no-console */
// TODO: This is meant to be temporary, we are working on adding the possibility of having local state on Forms
// we will be moving away from global states (including Singletons) when is not needed ASAP
import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import { defineStore } from 'pinia'
import posthog from 'posthog-js'

const _FEATURE_FLAGS = ['enable-ofe-in-fof', 'email-variants-guardrails', 'enable-calendar-sends'] as const
type FeatureFlag = typeof _FEATURE_FLAGS[number]

const LOCAL_FEATURE_FLAGS: FeatureFlag[] = (import.meta.env.VITE_LOCAL_FEATURE_FLAGS)?.split(',') || []

export const usePosthogStore = defineStore('PosthogStore', () => {
  const isPosthogEnabled = !!import.meta.env.VITE_POSTHOG_API_KEY && !!import.meta.env.VITE_POSTHOG_API_HOST
  const featureFlags = ref<FeatureFlag[]>([])
  const userEmail: string = store.getters['user/email']
  const userName: string = store.getters['user/username']
  const userRole: string = store.getters['user/role'].type

  onMounted(() => {
    if (isPosthogEnabled) {
      posthog.onFeatureFlags((flags) => {
        featureFlags.value = flags as FeatureFlag[] // We dont have access to the types, no other way besides `as` here
      })
    }
    else {
      featureFlags.value = LOCAL_FEATURE_FLAGS
    }
  })

  const { clientName, experimenterName } = AppSettings

  watch([userEmail, userName], () => {
    if (isPosthogEnabled) {
      posthog.identify(
        userEmail,
        { email: userEmail, name: userName, role: userRole },
      )
      posthog.reloadFeatureFlags()
    }
    else {
      console.debug('Posthog -> identify', { userEmail, userName, userRole, isPosthogEnabled })
    }
  }, { immediate: true })

  watch(clientName, () => {
    if (isPosthogEnabled) {
      posthog.setPersonProperties({ client: clientName.value || null })
    }
    else {
      console.debug('Posthog -> set person property', { client: clientName.value || null, isPosthogEnabled })
    }
  }, { immediate: true })

  watch(experimenterName, () => {
    if (isPosthogEnabled) {
      posthog.setPersonProperties({ experimenter: experimenterName.value || null })
    }
    else {
      console.debug('Posthog -> set person property', { experimenter: experimenterName.value || null, isPosthogEnabled })
    }
  }, { immediate: true })

  return {
    featureFlags,
  }
})
