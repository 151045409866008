import { store } from '@app/store'
import type { RouteRecordRaw, Router } from 'vue-router'

import {
  ACTIONS_ROUTE,
  AUDIENCE_CUSTOMER_GROUPS_ROUTE,
  AssetTabKey,
  CHANNELS_ROUTE,
  CONFIG_EDITOR_ROUTE,
  CONVERSION_ROUTE,
  CREATE_CLIENT_ROUTE,
  CUSTOM_REPORTING_ROUTE,
  DAG_LIST_ROUTE,
  DASHBOARD_ROUTE,
  DASHBOARD_USECASE_ROUTE,
  DATA_ASSETS_ROUTE,
  DATA_ASSET_DATA_JOINING_ROUTE,
  DATA_ASSET_FEATURES_ROUTE,
  DATA_ASSET_ROUTE,
  DATA_ASSET_TRANSFORMATIONS_ROUTE,
  DATA_ASSET_VALIDATION_ROUTE,
  DEPLOYMENTS_ROUTE,
  DIMENSIONS_ROUTE,
  DOCUMENTATION_ROUTE,
  DashboardTabKey,
  EVENTS_ROUTE,
  FEATURES_TO_USE_ROUTE,
  GUARDRAILS_ROUTE,
  OUTPUTS_ROUTE,
  PIPELINES_ROUTE,
  PREVIEW_ROUTE,
  PRIVILEGES_CLIENTS_ROUTE,
  PRIVILEGES_FEATURES_ROUTE,
  PRIVILEGES_ROLES_ROUTE,
  PRIVILEGES_ROUTE,
  PRIVILEGES_TABS,
  PRIVILEGES_USERS_ROUTE,
  REPORTING_ROUTE_NAMES,
  REPORTS_ROUTE,
  REWARD_ROUTE,
  REWARD_V2_ROUTE,
  ReportTabKey,
  USER_CLIENT_RELATION_ROUTE,
  USE_CASE_ROUTE,
  UseCaseTabKey,
} from '@shared/data/constants'
import {
  hasUserPrivilege,
  isAIEorAdmin,
  isAIEorAdminOrEp,
  redirectToDataAssetPage,
  redirectToUseCasePage,
} from './routerHelpers'
import type { ClientModel } from '@/clients/models/server/ClientModel.ts'
import { useUserPrivilegesForRouter } from '@/privileges/useUserPrivileges'

const ConfigEditorPage = () => import('@/configuration/pages/ConfigEditorPage.vue')
const ConnectionsPage = () => import('@/connections/pages/ConnectionsPage.vue')
const CreateClientPage = () => import('@/clients/pages/level3/CreateClient.page.vue')
const ClientRelationPage = () => import('@/clients/pages/level3/ClientRelation.page.vue')
const PrivilegesPage = () => import('@/privileges/pages/Privileges.page.vue')
const FeaturesPrivilegesTab = () => import('@/privileges/pages/FeaturesPrivilegesTab.page.vue')
const RolesPrivilegesTab = () => import('@/privileges/pages/RolesPrivilegesTab.page.vue')
const ClientsPrivilegesTab = () => import('@/privileges/pages/ClientsPrivilegesTab.page.vue')
const UsersPrivilegesTab = () => import('@/privileges/pages/UsersPrivilegesTab.page.vue')
const DagsPage = () => import('@/dags/pages/Dags.page.vue')
const DashboardPage = () => import('@/dashboard/pages/DashboardPage.vue')
const DataAssetListPage = () => import('@/data-assets/pages/DataAssetList.page.vue')
const DataAssetPage = () => import('@/data-assets/pages/DataAsset.page.vue')
const DeploymentsPage = () => import('@/deployments/pages/Deployments.page.vue')
const DocumentationPage = () => import('@/docs/pages/DocumentationPage.vue')
const PipelineListPage = () => import('@/pipelines/pages/PipelineList.page.vue')
const ReportsPage = () => import('@/reporting/shared/pages/ReportsPageWrapperL3.page.vue')
const UseCasePage = () => import('@/usecases/pages/UseCasePage.page.vue')
const DashboardUseCases = () => import('@/usecases/pages/DashboardUseCases.page.vue')
const DataJoiningTab = () => import('@/data-assets/pages/DataJoiningTab.vue')
const FeaturesTab = () => import('@/data-assets/pages/FeaturesTab.vue')
const PropertiesTab = () => import('@/data-assets/pages/PropertiesTab.vue')
const TransformationTab = () => import('@/data-assets/pages/TransformationTab.vue')
const ValidationTab = () => import('@/data-assets/pages/ValidationTab.vue')
const CalculateFeaturesPreviewPage = () => import('@/data-assets/views/previews/CalculateFeaturesPreviewPage.vue')
const FeatureStatisticsPreviewPage = () => import('@/data-assets/views/previews/FeatureStatisticsPreviewPage.vue')
const JoinedDataPreviewPage = () => import('@/data-assets/views/previews/JoinedDataPreviewPage.vue')
const PreprocessedDataPreview = () => import('@/data-assets/views/previews/PreprocessedDataPreviewPage.vue')
const SourceDataPreview = () => import('@/data-assets/views/previews/SourceDataPreviewPage.vue')
const TransformedDataPreviewPage = () => import('@/data-assets/views/previews/TransformedDataPreviewPage.vue')
const InboundDataValidationTab = () => import('@/reporting/inbound/pages/InboundDataValidationTab.page.vue')
const InboundMonitoringTab = () => import('@/reporting/inbound/pages/InboundMonitoringTab.page.vue')
const InsightsReportTab = () => import('@/reporting/insights/pages/InsightsReportTab.page.vue')
const ModelPreferencesReportTab = () => import('@/reporting/modelPreferences/pages/ModelPrefsV2.page.vue')
const OutboundMonitoringTab = () => import('@/reporting/outbound/pages/OutboundMonitoringTab.page.vue')
const PerformanceReportTab = () => import('@/reporting/performance/pages/UpliftReportV2.page.vue')
const SegmentReportTab = () => import('@/reporting/segments/pages/SegmentReportTab.page.vue')
const ShapReportTab = () => import('@/reporting/shaps/pages/ShapReportTab.page.vue')
const ValidioAlertsTab = () => import('@/reporting/validio/pages/ValidioAlertsTab.page.vue')
const ActionsTab = () => import('@/usecases/pages/ActionBanksTab.page.vue')
const ChannelsTab = () => import('@/usecases/pages/ChannelsTab.page.vue')
const ConversionTab = () => import('@/usecases/pages/ConversionTab.page.vue')
const RewardsTab = () => import('@/usecases/pages/RewardsTab.page.vue')
const AudienceCustomerGroupsTab = () => import('@/usecases/pages/AudienceCustomerGroupsTab.page.vue')
const DecisionDimensionTab = () => import('@/usecases/pages/DecisionDimensionTab.page.vue')
const EventsTab = () => import('@/usecases/pages/EventsTab.page.vue')
const FeaturesToUseTab = () => import('@/usecases/pages/FeaturesToUseTab.page.vue')
const GuardrailsTab = () => import('@/usecases/pages/GuardrailsTab.page.vue')
const OutputsTab = () => import('@/usecases/pages/OutputsTab.page.vue')
const RewardConfigurationTab = () => import('@/usecases/pages/RewardConfigurationTab.page.vue')

function getLevel3Routes(): RouteRecordRaw[] {
  return [
    {
      path: '/',
      name: DASHBOARD_ROUTE,
      component: DashboardPage,
      redirect: { name: DASHBOARD_USECASE_ROUTE },
      children: [
        {
          name: DASHBOARD_USECASE_ROUTE,
          path: DashboardTabKey.USE_CASES,
          component: DashboardUseCases,
        },
      ],
    },
    {
      name: DEPLOYMENTS_ROUTE,
      path: '/deployments',
      component: DeploymentsPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      name: PIPELINES_ROUTE,
      path: '/pipelines',
      component: PipelineListPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      name: 'DataAssetsRedirection',
      path: '/data-assets/:assetKey',
      children: [
        {
          path: AssetTabKey.PROPERTIES,
          redirect: redirectToDataAssetPage,
        },
        {
          path: AssetTabKey.VALIDATE,
          redirect: redirectToDataAssetPage,
        },
        {
          path: AssetTabKey.PREPROCESS,
          redirect: redirectToDataAssetPage,
        },
        {
          path: AssetTabKey.DATA_JOINING,
          redirect: redirectToDataAssetPage,
        },
        {
          path: AssetTabKey.TRANSFORMATION,
          redirect: redirectToDataAssetPage,
        },
        {
          path: AssetTabKey.FEATURES,
          redirect: redirectToDataAssetPage,
        },
      ],
      redirect: redirectToDataAssetPage,
    },
    {
      path: '/data-assets',
      name: DATA_ASSETS_ROUTE,
      component: DataAssetListPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      path: '/data-asset/:assetKey',
      name: DATA_ASSET_ROUTE,
      component: DataAssetPage,
      beforeEnter: isAIEorAdminOrEp,
      props: true,
      redirect: { name: 'Properties' },
      children: [
        {
          name: 'Properties',
          path: AssetTabKey.PROPERTIES,
          component: PropertiesTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: DATA_ASSET_VALIDATION_ROUTE,
          path: AssetTabKey.VALIDATE,
          component: ValidationTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: DATA_ASSET_DATA_JOINING_ROUTE,
          path: AssetTabKey.DATA_JOINING,
          component: DataJoiningTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: DATA_ASSET_TRANSFORMATIONS_ROUTE,
          path: AssetTabKey.TRANSFORMATION,
          component: TransformationTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: DATA_ASSET_FEATURES_ROUTE,
          path: AssetTabKey.FEATURES,
          component: FeaturesTab,
          beforeEnter: isAIEorAdminOrEp,
        },
      ],
    },
    {
      path: '/raw-data/:assetKey',
      name: PREVIEW_ROUTE.RAW_DATA,
      component: SourceDataPreview,
      beforeEnter: isAIEorAdminOrEp,
      props: true,
    },
    {
      path: '/preprocessed-data/:assetKey',
      name: PREVIEW_ROUTE.PREPROCESSED_DATA,
      component: PreprocessedDataPreview,
      beforeEnter: isAIEorAdminOrEp,
      props: true,
    },
    {
      path: '/joined-data/:assetKey',
      name: PREVIEW_ROUTE.JOINED_DATA,
      component: JoinedDataPreviewPage,
      beforeEnter: isAIEorAdminOrEp,
      props: true,
    },
    {
      path: '/transformed-data/:assetKey',
      name: PREVIEW_ROUTE.TRANSFORMED_DATA,
      component: TransformedDataPreviewPage,
      beforeEnter: isAIEorAdminOrEp,
      props: true,
    },
    {
      path: '/feature-calculation',
      name: 'CalculateFeaturesPreview',
      component: CalculateFeaturesPreviewPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      path: '/feature-statistics',
      name: 'FeatureStatisticsPreview',
      component: FeatureStatisticsPreviewPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      path: '/connections',
      name: 'Connections',
      component: ConnectionsPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      path: '/documentation',
      name: DOCUMENTATION_ROUTE,
      component: DocumentationPage,
      meta: {
        roles: ['reader'],
      },
      beforeEnter: async (to) => {
        const clientName = computed(() => (store.getters['client/client'] as ClientModel)?.name)
        const { isRouteEnabledByName } = await useUserPrivilegesForRouter(clientName)
        return isRouteEnabledByName(DOCUMENTATION_ROUTE)
      },
    },
    {
      path: '/reports',
      name: REPORTS_ROUTE,
      component: ReportsPage,
      beforeEnter: async (to) => {
        if (to.name === REPORTS_ROUTE) {
          const clientName = computed(() => (store.getters['client/client'] as ClientModel)?.name)
          const { allowedReports } = await useUserPrivilegesForRouter(clientName)
          if (allowedReports.value?.length) {
            return { name: allowedReports.value.includes(REPORTING_ROUTE_NAMES.UPLIFT) ? REPORTING_ROUTE_NAMES.UPLIFT : (allowedReports.value[0] ?? DASHBOARD_ROUTE) }
          }
          return { name: REPORTING_ROUTE_NAMES.UPLIFT }
        }

        return true
      },
      meta: {
        roles: ['reader'],
      },
      children: [
        {
          name: REPORTING_ROUTE_NAMES.UPLIFT,
          path: ReportTabKey.USE_CASE_PERFORMANCE,
          component: PerformanceReportTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.INBOUND_VALIDATION,
          path: ReportTabKey.INBOUND_DATA_VALIDATION,
          component: InboundDataValidationTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.SEGMENTS,
          path: ReportTabKey.SEGMENT_REPORT,
          component: SegmentReportTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.INBOUND,
          path: ReportTabKey.INBOUND_MONITORING,
          component: InboundMonitoringTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.OUTBOUND,
          path: ReportTabKey.OUTBOUND_MONITORING,
          component: OutboundMonitoringTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.INSIGHTS,
          path: ReportTabKey.INSIGHTS,
          component: InsightsReportTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.MODEL_PREFERENCES,
          path: ReportTabKey.MODEL_PREFERENCES,
          component: ModelPreferencesReportTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.SHAPS,
          path: ReportTabKey.SHAP_REPORT,
          component: ShapReportTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
        {
          name: REPORTING_ROUTE_NAMES.VALIDIO,
          path: ReportTabKey.VALIDIO_ALERTS,
          component: ValidioAlertsTab,
          beforeEnter: hasUserPrivilege,
          meta: {
            roles: ['reader'],
          },
        },
      ],
    },
    {
      path: '/use-cases/:usecaseName',
      redirect: redirectToUseCasePage,
      children: [
        {
          path: UseCaseTabKey.EVENTS,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.CUSTOMER_GROUPS,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.FEATURES_TO_USE,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.DECISION_DIMENSION,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.REWARD_CONFIGURATION,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.ACTION_BANKS,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.GUARDRAILS,
          redirect: redirectToUseCasePage,
        },
        {
          path: UseCaseTabKey.OUTPUTS,
          redirect: redirectToUseCasePage,
        },
      ],
    },
    {
      path: '/use-case/:usecaseName',
      name: USE_CASE_ROUTE,
      props: true,
      component: UseCasePage,
      beforeEnter: isAIEorAdminOrEp,
      redirect: { name: DIMENSIONS_ROUTE },
      children: [
        {
          name: EVENTS_ROUTE,
          path: UseCaseTabKey.EVENTS,
          component: EventsTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: AUDIENCE_CUSTOMER_GROUPS_ROUTE,
          path: UseCaseTabKey.CUSTOMER_GROUPS,
          component: AudienceCustomerGroupsTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: FEATURES_TO_USE_ROUTE,
          path: UseCaseTabKey.FEATURES_TO_USE,
          component: FeaturesToUseTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: DIMENSIONS_ROUTE,
          path: UseCaseTabKey.DECISION_DIMENSION,
          component: DecisionDimensionTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: REWARD_ROUTE,
          path: UseCaseTabKey.REWARD_CONFIGURATION,
          component: RewardConfigurationTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: ACTIONS_ROUTE,
          path: UseCaseTabKey.ACTION_BANKS,
          component: ActionsTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: GUARDRAILS_ROUTE,
          path: UseCaseTabKey.GUARDRAILS,
          component: GuardrailsTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: OUTPUTS_ROUTE,
          path: UseCaseTabKey.OUTPUTS,
          component: OutputsTab,
          beforeEnter: isAIEorAdminOrEp,
        },
        {
          name: CHANNELS_ROUTE,
          path: UseCaseTabKey.CHANNELS,
          beforeEnter: isAIEorAdminOrEp,
          component: ChannelsTab,
        },
        {
          name: CONVERSION_ROUTE,
          path: UseCaseTabKey.CONVERSION,
          beforeEnter: isAIEorAdminOrEp,
          component: ConversionTab,
        },
        {
          name: REWARD_V2_ROUTE,
          path: UseCaseTabKey.REWARDS,
          beforeEnter: isAIEorAdminOrEp,
          component: RewardsTab,
        },
        {
          name: CUSTOM_REPORTING_ROUTE,
          path: UseCaseTabKey.CUSTOM_REPORTING,
          beforeEnter: isAIEorAdminOrEp,
          component: () => import('@/usecases/pages/CustomReporting.page.vue'),
        },
      ],
    },
    {
      path: '/new-client',
      name: CREATE_CLIENT_ROUTE,
      component: CreateClientPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      path: '/dags',
      name: DAG_LIST_ROUTE,
      component: DagsPage,
      beforeEnter: isAIEorAdminOrEp,
    },
    {
      path: '/user-groups',
      name: USER_CLIENT_RELATION_ROUTE,
      component: ClientRelationPage,
      beforeEnter: isAIEorAdmin,
    },
    {
      path: '/privileges',
      name: PRIVILEGES_ROUTE,
      component: PrivilegesPage,
      redirect: { name: PRIVILEGES_CLIENTS_ROUTE },
      children: [
        {
          name: PRIVILEGES_CLIENTS_ROUTE,
          path: PRIVILEGES_TABS.CLIENTS.toLowerCase(),
          component: ClientsPrivilegesTab,
          beforeEnter: hasUserPrivilege,
        },
        {
          name: PRIVILEGES_USERS_ROUTE,
          path: PRIVILEGES_TABS.USERS.toLowerCase(),
          component: UsersPrivilegesTab,
          beforeEnter: hasUserPrivilege,
        },
        {
          name: PRIVILEGES_ROLES_ROUTE,
          path: PRIVILEGES_TABS.ROLES.toLowerCase(),
          component: RolesPrivilegesTab,
          beforeEnter: hasUserPrivilege,
        },
        {
          name: PRIVILEGES_FEATURES_ROUTE,
          path: PRIVILEGES_TABS.FEATURES.toLowerCase(),
          component: FeaturesPrivilegesTab,
          beforeEnter: hasUserPrivilege,
        },
      ],
    },
    {
      path: '/config-editor',
      name: CONFIG_EDITOR_ROUTE,
      component: ConfigEditorPage,
      beforeEnter: isAIEorAdminOrEp,
    },
  ]
}

export function initL3Routes(router: Router) {
  // DON'T ADD ASYNC OPERATIONS HERE
  const routes = getLevel3Routes()
  routes.forEach(route => router.addRoute(route))
}
