const dateUtils = {
  dateToRelativeString(date: Date) {
    if (!date) { return '' }

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })
    const diff = date.getTime() - Date.now()

    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((diff / (1000 * 60)) % 60)
    const seconds = Math.floor((diff / 1000) % 60)

    if (Math.abs(days) > 0) {
      return rtf.format(days, 'day')
    }
    else if (Math.abs(hours) > 0) {
      return rtf.format(hours, 'hour')
    }
    else if (Math.abs(minutes) > 0) {
      return rtf.format(minutes, 'minute')
    }
    else {
      return rtf.format(seconds, 'second')
    }
  },
}

export default dateUtils
