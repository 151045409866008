<script lang="ts">
/**
 * If using getFormattedValue (prop set as true), we need to use the right format as model value, the same
 * used in the format prop, it should be a string (i.e. '2021-09-15'), also, if you use a timestamp the component
 * will not work properly
 */

import { format, getTime } from 'date-fns'
import debounce from 'lodash/debounce'
import type { DatePickerProps } from 'naive-ui'

import { ChangeStatus, REQUEST_DEBOUNCE_DELAY } from '@shared/data/constants'
import { store } from '@app/store'
import type { DatetimePickerTypes, NullString } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import { validateDateWithStringFormat } from '@shared/utils/helpers'

type DatePickerThemeOverrides = NonNullable<DatePickerProps['themeOverrides']>

const themeOverrides: DatePickerThemeOverrides = {
  common: {
    primaryColor: '#22BD98',
  } as any,
  peers: {
    Input: {
      fontSizeSmall: '12px',
      borderFocus: '1px solid #22BD98',
      borderHover: '1px solid #22BD98',
    },
    Button: {
      colorPrimary: '#22BD98',
      colorHoverPrimary: '#34d399',
      borderPrimary: '1px solid #22BD98',
      borderHoverPrimary: '1px solid #34d399',
    },
  },
}

export default defineComponent({
  name: 'TableEditableDatetime',
  props: {
    id: String as PropType<string>,
    pickerType: {
      type: String as PropType<DatetimePickerTypes>,
      default: 'datetime',
    },
    placeholder: String as PropType<NullString>,
    format: {
      type: String,
      default: 'LLLL dd, yyyy',
    },
    emptyPlaceholder: {
      type: String as PropType<NullString>,
      default: ' ',
    },
    modelValue: {
      type: String as PropType<NullString>,
      default: null,
    },
    getFormattedValue: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    error: [String, Object] as PropType<NullString | Ref>,
    suffix: String as PropType<NullString>,
    changedStatus: String as PropType<ChangeStatus>,
    showInput: Boolean as PropType<boolean>,
    underlined: Boolean as PropType<boolean>,
    useDisableDate: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Function,
      default: () => {
        return false
      },
    },
    size: String as PropType<'small' | 'medium' | 'large'>,
  },
  emits: ['update:modelValue', 'blur'],
  setup(props, context) {
    const isEdit = ref(false)
    const inputRef = ref<any>(null)

    const localValue: Ref = ref<number | null>(getTime(new Date(props.modelValue as string)))
    const localErrorText = ref<string>('')

    // If using getFormattedValue, we need to use the right format as model value, the same
    // passed through the format prop
    const formattedLocalValue: Ref = ref<NullString>(props.modelValue)

    const valueTitle: ComputedRef<NullString> = computed<NullString>(() => {
      let titleString: NullString = `${props.getFormattedValue ? formattedLocalValue.value : localValue.value}${
        props.suffix ? props.suffix : ''
      }`

      const errorMessage: NullString = isRef(errorText) ? errorText.value : (errorText as string)

      if (errorMessage) {
        titleString = (localValue.value ? `${titleString} - ${errorMessage}` : errorMessage) as string
      }

      return titleString
    })

    let ignoreLocalValueChange: boolean = false

    let unWatchModelValue: WatchStopHandle
    let unWatchLocalValue: WatchStopHandle

    onMounted(() => {
      const isValidDate = validateDateWithStringFormat(props.modelValue || '')

      if (props.modelValue && !isValidDate) {
        formattedLocalValue.value = null
        localErrorText.value = 'Invalid date format'
      }

      unWatchModelValue = watch(
        () => [props.modelValue],
        () => {
          ignoreLocalValueChange = true
          localValue.value = Number(props.modelValue)
          formattedLocalValue.value = props.modelValue
          nextTick(() => {
            ignoreLocalValueChange = false
          })
        },
      )

      unWatchLocalValue = watch([localValue, formattedLocalValue], () => {
        if (ignoreLocalValueChange) { return }
        if (props.getFormattedValue) {
          context.emit('update:modelValue', formattedLocalValue.value)
        }
        else {
          context.emit('update:modelValue', localValue.value)
        }
        debouncedFieldChange()
      })
    })

    onBeforeUnmount(() => {
      unWatchModelValue()
      unWatchLocalValue()
    })

    const clickHandler = () => {
      isEdit.value = true
      nextTick(() => {
        inputRef.value?.focus?.()
      })
    }

    const errorText: ComputedRef<NullString> = computed<NullString>(() => {
      if (localErrorText.value) { return localErrorText.value }
      return isRef(props.error) ? (props.error as Ref)?.value : props.error
    })

    const formattedValue: ComputedRef<NullString> = computed<NullString>(() => {
      if (errorText.value) { return props.modelValue }

      if ((localValue?.value || formattedLocalValue.value) && props.format) {
        if (props.getFormattedValue) {
          return formattedLocalValue.value
        }
        else {
          return format(localValue.value, props.format)
        }
      }

      return props.emptyPlaceholder || ''
    })

    const blurAndEmitData = () => {
      if (props.getFormattedValue) {
        context.emit('blur', formattedLocalValue.value)
      }
      else {
        context.emit('blur', localValue.value)
      }
    }

    const inputBlurCapturedHandler = () => {
      blurAndEmitData()
    }
    const enterKeyDownCaptureHandler = () => {
      blurAndEmitData()
    }

    const blurInput = () => {
      if (!errorText.value) {
        isEdit.value = false
      }
    }

    const inputBlurHandler = () => {
      blurInput()
    }
    const enterKeyDownHandler = () => {
      blurInput()
    }

    const debouncedFieldChange = debounce(() => {
      if (props.id) {
        trackEvent(props.id, {
          type: 'table-date-time',
          action: 'change',
          value: localValue.value,
        })
      }
    }, REQUEST_DEBOUNCE_DELAY)

    const disableDate = (ts: number) => {
      if (props.useDisableDate) {
        return props.disabledDates(ts)
      }
    }

    return {
      isNaN,
      isEdit,
      inputRef,
      ChangeStatus,
      clickHandler,
      disableDate,
      localValue,
      valueTitle,
      errorText,
      themeOverrides,
      formattedLocalValue,
      formattedValue,
      inputBlurHandler,
      enterKeyDownHandler,
      inputBlurCapturedHandler,
      enterKeyDownCaptureHandler,
      localErrorText,
      globalReadOnlyMode: computed(() => store.getters.isReadonlyMode),
    }
  },
})
</script>

<template>
  <div
    :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']"
    class="relative"
    @keydown.enter="enterKeyDownHandler"
    @keydown.enter.capture="enterKeyDownCaptureHandler"
  >
    <div
      v-if="!(isEdit || showInput)"
      :title="valueTitle || ''"
      style="padding-top: 3px; padding-left: 5px; padding-right: 3px"
      class="h-full w-full p-1"
      :class="{
        'cursor-pointer hover:bg-purple-100 rounded-md border-dashed border border-gray-300': !globalReadOnlyMode,
        'underline': underlined,
        'text-error': !!localErrorText ? localErrorText : !!errorText && (localValue || isNaN(localValue)),
        'text-gray-400': localValue === '' && !errorText,
        'text-red-200': localValue === '' && !!errorText,
        'bg-yellow-100 hover:bg-yellow-100': changedStatus === ChangeStatus.CHANGED,
        'bg-red-100 hover:bg-red-100': changedStatus === ChangeStatus.DELETED,
        'bg-green-100 hover:bg-green-100': changedStatus === ChangeStatus.ADDED,
      }"
      @click="clickHandler"
    >
      {{ formattedValue || emptyPlaceholder }}{{ suffix ? suffix : '' }}
    </div>
    <div v-else-if="getFormattedValue">
      <NDatePicker
        :id="id"
        ref="inputRef"
        v-model:formatted-value="formattedLocalValue"
        :type="pickerType"
        :themeOverrides="themeOverrides"
        :format="format"
        size="small"
        clearable
        closeOnSelect
        :status="errorText ? 'error' : 'success'"
        :isDateDisabled="disableDate"
        :actions="actions"
        @blur="inputBlurHandler"
        @blur.capture="inputBlurCapturedHandler"
      />
    </div>
    <NDatePicker
      v-else
      :id="id"
      ref="inputRef"
      v-model:value="localValue"
      :type="pickerType"
      :themeOverrides="themeOverrides"
      :format="format"
      :size="size || 'medium'"
      clearable
      closeOnSelect
      @blur="inputBlurHandler"
      @blur.capture="inputBlurCapturedHandler"
    />
  </div>
</template>

<style scoped>
:deep(.simple-input) input {
  @apply py-1;
}
</style>
